<template>
  <BaseChart :dimensions="dimensions">
    <template #content>
      <g v-for="{ x, y, width, height, key } in rects" :key="key">
        <rect
          :x="x"
          :y="y"
          :width="width"
          :height="height"
          fill="cornflowerblue"
        ></rect>
      </g>
      <g v-for="{ y, value, key } in quantiles" :key="key">
        <line
          :y1="y"
          :y2="y"
          :x1="0"
          :x2="dimensions.boundedWidth"
          stroke="black"
        />
        <text :y="y" :x="dimensions.boundedWidth + 15" text-anchor="middle">
          {{ value }}
        </text>
      </g>
    </template>
    <template #axis>
      <AxisBottom
        :scale="xScale"
        :dimensions="dimensions"
        label="Date"
        :format="formatDate"
      />
      <AxisLeft :scale="yScale" :dimensions="dimensions" label="Throughput" />
    </template>
  </BaseChart>
</template>
<script>
import { scaleTime, scaleLinear, extent, quantile } from "d3";

import AxisBottom from "@/components/AxisBottom";
import AxisLeft from "@/components/AxisLeft";
import BaseChart from "@/components/BaseChart";
import { calculateThroughput } from "@/math/throughput";
import { trimDate, addOneDay, formatDate } from "@/math/weekends";
import { mapStoryPoints } from "@/math/story-points";

export default {
  props: ["dimensions", "data", "settings"],
  components: { AxisBottom, AxisLeft, BaseChart },
  setup(props) {
    const throuputData = calculateThroughput({
      issues: props.data,
      doneStates: new Set(props.settings.workflow.done.map(({ id }) => id)),
    });
    const xAccessor = (d) => d.date;
    const xScale = scaleTime()
      .domain(extent(throuputData, xAccessor))
      .range([0, props.dimensions.boundedWidth])
      .nice();
    const yAccessor = (d) =>
      d.issues.reduce(
        (acc, cur) => acc + mapStoryPoints(cur.fields["Story Points"]),
        0
      );
    const yScale = scaleLinear()
      .domain(extent(throuputData, yAccessor))
      .range([props.dimensions.boundedHeight, 0])
      .nice();
    const width =
      xScale(addOneDay(trimDate(new Date()))) - xScale(trimDate(new Date()));
    const rects = throuputData.map((d, idx) => ({
      key: idx,
      x: xScale(xAccessor(d)),
      y: yScale(yAccessor(d)),
      width,
      height: props.dimensions.boundedHeight - yScale(yAccessor(d)),
    }));
    const throuputValues = throuputData.map(yAccessor);
    const quantiles = [0.5, 0.7, 0.85, 0.95]
      .map((q) => Math.round(quantile(throuputValues, q)))
      .map((value, idx) => ({ y: yScale(value), value, key: idx }));
    return { rects, xScale, yScale, formatDate, quantiles };
  },
};
</script>
