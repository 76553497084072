<template>
  <g
    id="axis-bottom"
    :style="
      `transform: translate(${
        dimensions.margin.left
      }px, ${dimensions.boundedHeight + dimensions.margin.top}px)`
    "
  >
    <line x="0" :x2="dimensions.boundedWidth" stroke="currentColor" />
    <g
      v-for="{ value, offset } in ticks"
      :key="value"
      :style="`transform: translate(${offset}px, 0)`"
    >
      <line y="0" y2="6" stroke="currentColor" />
      <text
        :style="{
          fontSize: '15px',
          textAnchor: 'middle',
          transform: 'translateY(20px)',
        }"
      >
        {{ value }}
      </text>
    </g>
    <text
      :style="{
        fontSize: '15px',
        textAnchor: 'middle',
        transform: `translate(${dimensions.boundedWidth / 2}px,40px)`,
      }"
    >
      {{ label }}
    </text>
  </g>
</template>
<script>
export default {
  props: {
    scale: { type: Function, required: true },
    dimensions: { type: Object, required: true },
    format: {
      type: Function,
      default: (value) => value,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    ticks() {
      return this.scale.ticks().map((value) => ({
        value: this.format(value),
        offset: this.scale(value),
      }));
    },
  },
};
</script>
