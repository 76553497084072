import { group } from "d3";
import { addOneDay, trimDate } from "@/math/weekends";

const getDoneDate = ({ issue, doneStates }) => {
  if (issue.changelog.length === 0) {
    return null;
  }
  const changelog = [...issue.changelog].sort((a, b) =>
    b.date.localeCompare(a.date)
  );

  if (!doneStates.has(changelog[0].from)) {
    return null;
  }
  const lastWorkedOnLog = changelog.find(({ from }) => !doneStates.has(from));
  if (!lastWorkedOnLog) {
    return null;
  }
  return { issue, date: new Date(lastWorkedOnLog.date) };
};

export const calculateThroughput = ({ issues, doneStates }) => {
  const doneDates = issues
    .map((issue) => getDoneDate({ issue, doneStates }))
    .filter(Boolean)
    .sort((a, b) => a.date - b.date);
  if (doneDates.length < 2) {
    return null;
  }
  const groupedDates = group(doneDates, ({ date }) =>
    trimDate(date).toISOString()
  );
  const firstDate = doneDates[0].date;
  const lastDate = doneDates.slice(-1)[0].date;
  let current = trimDate(firstDate);
  const result = [];
  while (current < lastDate) {
    if (groupedDates.get(current.toISOString())) {
      result.push({
        date: current,
        issues: groupedDates
          .get(current.toISOString())
          .map(({ issue }) => issue),
      });
    } else {
      result.push({ date: current, issues: [] });
    }
    current = addOneDay(current);
  }
  return result;
};
window.test = calculateThroughput;
