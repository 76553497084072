import { calcDuration } from "@/math/weekends";

export const getStates = (issue) => {
  const stateMap = {};
  for (const log of issue.changelog) {
    stateMap[log.from] = { id: log.from, name: log.fromString };
  }
  return Object.values(stateMap);
};

export const calcInStateTimes = (issue) => {
  const { changelog } = issue;
  if (changelog.length === 0) {
    return null;
  }
  changelog.sort((a, b) => a.date.localeCompare(b.date));

  const times = Object.fromEntries(
    getStates(issue).map(({ id, name }) => [id, { numberOfHours: 0, id, name }])
  );
  // console.log(times);
  // TODO: add creation date instead of first changelog date
  let lastDate = new Date(changelog[0].date);
  for (const item of changelog) {
    times[item.from].numberOfHours +=
      calcDuration({
        start: lastDate,
        end: new Date(item.date),
      }) /
      (60 * 60 * 1000);
    lastDate = new Date(item.date);
  }
  return times;
};

export const createCycleTimeCalculator = ({ doneStates, inProcessStates }) => (
  issue
) => {
  const doneStateSet = new Set(doneStates.map(({ id }) => id));
  const inProcessStateSet = new Set(inProcessStates.map(({ id }) => id));

  const { changelog } = issue;
  if (changelog.length === 0) {
    return null;
  }
  changelog.sort((a, b) => a.date.localeCompare(b.date));

  const lastState = changelog.slice(-1)[0].to;
  if (!doneStateSet.has(lastState)) {
    return null;
  }

  const inStateTimes = calcInStateTimes(issue);
  const durationInDays =
    Object.entries(inStateTimes)
      .filter(([id]) => inProcessStateSet.has(id))
      .reduce((acc, [, { numberOfHours }]) => acc + numberOfHours, 0) / 24;
  return durationInDays;
};
