<template>
  <div>
    <input
      ref="input"
      @change="loadNewData"
      type="file"
      style="display: none"
    />
    <slot :chooseFile="clickInput">
      <button @click="clickInput">Load File</button>
    </slot>
  </div>
</template>
<script>
export default {
  emits: ["loadedData"],
  methods: {
    clickInput() {
      this.$refs.input.click();
    },
    loadNewData(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.$emit("loadedData", reader.result);
      });
      reader.readAsText(file, "utf-8");
    },
  },
};
</script>