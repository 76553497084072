<template>
  <h2>Cycle Time-Story Points</h2>
  <div
    v-for="{
      start,
      end,
      cycleScale,
      countScale,
      rects,
      quantiles,
      count,
    } in storyPoints.map(createEverything)"
    :key="start"
  >
    <p>
      Story Points: {{ start + 0.001 }} - {{ end }} (Number of Issues:
      {{ count }})
    </p>
    <svg :width="dim.width" :height="dim.height">
      <rect
        :x="dim.margin.left"
        :y="dim.margin.top"
        :width="dim.boundedWidth"
        :height="dim.boundedHeight"
        fill="none"
        stroke="black"
      />
      <rect
        :x="0"
        :y="0"
        :width="dim.width"
        :height="dim.height"
        fill="none"
        stroke="black"
      />
      <g
        :style="
          `transform: translate(${dim.margin.left}px, ${dim.margin.top}px)`
        "
      >
        <g v-for="{ x, y, width, height, key } in rects" :key="key">
          <rect
            :x="x"
            :y="y"
            :width="width"
            :height="height"
            fill="cornflowerblue"
          ></rect>
        </g>
        <g v-for="{ x, value, key } in quantiles" :key="key">
          <line
            :x1="x"
            :x2="x"
            :y1="0"
            :y2="dim.boundedHeight"
            stroke="black"
          />
          <text :x="x" :y="-2" text-anchor="middle">
            {{ value }}
          </text>
        </g>
      </g>
      <AxisBottom
        :scale="cycleScale"
        :dimensions="dim"
        label="Cycle Time (days)"
      />
      <AxisLeft
        :scale="countScale"
        :dimensions="dim"
        label="Number of Occurrences"
      />
    </svg>
  </div>
</template>
<script>
import { scaleLinear, group, histogram, range, quantile, max } from "d3";

import AxisBottom from "./AxisBottom";
import AxisLeft from "./AxisLeft";
import { createCycleTimeCalculator } from "@/math/cycle-time";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    settings: {
      required: true,
    },
  },
  components: {
    AxisBottom,
    AxisLeft,
  },
  data() {
    return {
      dimensions: {
        width: 600,
        height: 400,
        margin: { top: 30, right: 15, bottom: 50, left: 50 },
      },
      storyPoints: [
        [0, 1.0],
        [1.0, 3.0],
        [3.0, 50.0],
        [0.0, 50.0],
      ],
    };
  },
  computed: {
    groups() {
      const gs = group(this.data, (d) => d.fields["Story Points"]);
      return gs;
    },
    dim() {
      return {
        ...this.dimensions,
        boundedWidth:
          this.dimensions.width -
          this.dimensions.margin.left -
          this.dimensions.margin.right,
        boundedHeight:
          this.dimensions.height -
          this.dimensions.margin.top -
          this.dimensions.margin.bottom,
      };
    },
  },
  methods: {
    calcBins(cycleTimes, xScale) {
      const b = histogram()
        .domain(xScale.domain())
        .thresholds(range(60))(cycleTimes)
        .map((bin, idx) => {
          bin.idx = idx;
          return bin;
        });
      return b;
    },
    calcCycleTimes(data) {
      const cycleTimeCalculator = createCycleTimeCalculator({
        doneStates: this.settings.workflow.done,
        inProcessStates: this.settings.workflow.inProcess,
      });
      const cycleTimes = data.map(cycleTimeCalculator);
      return cycleTimes.filter(Boolean).filter((cycleTime) => cycleTime < 100);
    },
    calcQuantile(bins, q = 0.9) {
      return Math.round(quantile(bins.flat(), q));
    },
    createXSCale(cycleTimes) {
      return scaleLinear()
        .domain([0, this.calcQuantile(cycleTimes, 0.95) + 2])
        .range([0, this.dim.boundedWidth]);
    },
    createYScale(bins) {
      return scaleLinear()
        .domain([0.0, max(bins, (bin) => bin.length / (bin.x1 - bin.x0))])
        .range([this.dim.boundedHeight, 0]);
    },
    createEverything([start, end]) {
      const binAccessor = (bin) => bin.length / (bin.x1 - bin.x0);
      let data = [];
      for (const g of this.groups.keys()) {
        if (Number.isFinite(g) && start < g && g <= end) {
          data = [...data, ...this.groups.get(g)];
        }
      }
      const cycleTimes = this.calcCycleTimes(data);
      const xScale = this.createXSCale(cycleTimes);
      const bins = this.calcBins(cycleTimes, xScale);
      const yScale = this.createYScale(bins);

      if (max(bins, binAccessor) === 0) {
        return {
          cycleScale: xScale,
          countScale: yScale,
          rects: [],
          quantiles: [],
          storyPoint: end,
          count: 0,
        };
      }

      const rects = bins.map((bin, idx) => ({
        key: idx,
        x: xScale(bin.x0),
        y: yScale(binAccessor(bin)),
        width: xScale(bin.x1) - xScale(bin.x0),
        height: this.dim.boundedHeight - yScale(binAccessor(bin)),
      }));
      const quantiles = [0.5, 0.7, 0.85, 0.95]
        .map((q) => this.calcQuantile(bins, q))
        .map((real, idx) => ({ x: xScale(real + 1), value: real, key: idx }));
      const r = {
        cycleScale: xScale,
        countScale: yScale,
        rects,
        quantiles,
        start,
        end,
        count: cycleTimes.length,
      };
      return r;
    },
  },
};
</script>
