<template>
  <div>
    <select v-model="settings.profile">
      <option v-for="key in Object.keys(settings.profiles)" :key="key">
        {{ key }}
      </option>
    </select>
    <button @click.prevent="saveProfile">Save to Profile</button>
    <button @click.prevent="loadProfile">Load from Profile</button>

    <h2>Choose Issue Types:</h2>
    <div class="row">
      <div>
        <h3>Ignore</h3>
        <draggable
          class="list-group"
          :list="settings.issues.ignore"
          group="issues"
          itemKey="id"
          @change="persist"
        >
          <template #item="{ element }">
            <div class="list-group-item">
              {{ element.name }} ({{ element.id }})
            </div>
          </template>
        </draggable>
      </div>

      <div>
        <h3>Analyze</h3>
        <draggable
          class="list-group"
          :list="settings.issues.analyze"
          group="issues"
          itemKey="id"
          @change="persist"
        >
          <template #item="{ element }">
            <div class="list-group-item">
              {{ element.name }} ({{ element.id }})
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <h2>Map States:</h2>
    <div class="row">
      <div>
        <h3>Backlog</h3>
        <draggable
          class="list-group"
          :list="settings.workflow.ignore"
          group="workflow"
          itemKey="id"
          @change="persist"
        >
          <template #item="{ element }">
            <div class="list-group-item">
              {{ element.name }} ({{ element.id }})
            </div>
          </template>
        </draggable>
      </div>

      <div>
        <h3>In Process</h3>
        <draggable
          class="list-group"
          :list="settings.workflow.inProcess"
          group="workflow"
          itemKey="id"
          @change="persist"
        >
          <template #item="{ element }">
            <div class="list-group-item">
              {{ element.name }} ({{ element.id }})
            </div>
          </template>
        </draggable>
      </div>

      <div>
        <h3>Done</h3>
        <draggable
          class="list-group"
          :list="settings.workflow.done"
          group="workflow"
          itemKey="id"
          @change="persist"
        >
          <template #item="{ element }">
            <div class="list-group-item">
              {{ element.name }} ({{ element.id }})
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <button @click.prevent="reloadStates">
      Discard Settings + Reload from Data
    </button>
  </div>
</template>
<script>
import draggable from "vuedraggable";

const copy = (obj) => JSON.parse(JSON.stringify(obj));
const getIssueTypes = (data) => {
  return data.reduce((acc, issue) => {
    acc[issue.type.id] = issue.type;
    return acc;
  }, {});
};
const getStates = (data) => {
  const stateMap = {};
  for (const issue of data) {
    for (const log of issue.changelog) {
      stateMap[log.to] = { id: log.to, name: log.toString };
    }
  }
  return stateMap;
};

export default {
  props: [],
  components: {
    draggable,
  },
  mounted() {
    this.settings = copy(this.$store.state.settings);
  },
  computed: {
    data() {
      return this.$store.state.data;
    },
  },
  data() {
    return {
      settings: {
        profile: "BK",
        profiles: {
          BC: {},
          BK: {},
        },
        issues: {
          ignore: [],
          analyze: [],
        },
        workflow: {
          ignore: [],
          inProcess: [],
          done: [],
        },
      },
    };
  },
  methods: {
    persist() {
      this.$store.commit("setSettings", Object.freeze(copy(this.settings)));
    },
    reloadStates() {
      const stateMap = getStates(this.data);
      const issueMap = getIssueTypes(this.data);
      this.settings = {
        ...this.settings,
        issues: {
          ignore: [],
          analyze: Object.values(issueMap),
        },
        workflow: {
          ignore: Object.values(stateMap),
          inProcess: [],
          done: [],
        },
      };
      this.persist();
    },
    saveProfile() {
      this.settings.profiles[this.settings.profile] = copy({
        issues: this.settings.issues,
        workflow: this.settings.workflow,
      });
      this.persist();
    },
    loadProfile() {
      const { issues, workflow } = copy(
        this.settings.profiles[this.settings.profile]
      );
      this.settings.issues = issues;
      this.settings.workflow = workflow;
      this.persist();
    },
  },
};
</script>

<style scoped>
.list-group-item {
  display: block;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
}

.row {
  display: flex;
}
</style>
