<template>
  <g
    id="y-axis"
    :style="
      `transform: translate(${dimensions.margin.left}px, ${dimensions.margin.top}px)`
    "
  >
    <line y="0" :y2="dimensions.boundedHeight" stroke="currentColor" />
    <g
      v-for="{ value, offset } in ticks"
      :key="value"
      :style="`transform: translateY(${offset}px)`"
    >
      <line x="0" x2="-6" stroke="currentColor" />
      <text
        :style="{
          fontSize: '15px',
          dominantBaseline: 'middle',
          textAnchor: 'end',
          transform: 'translate(-10px)',
        }"
      >
        {{ value }}
      </text>
    </g>
    <text
      :style="{
        fontSize: '15px',
        textAnchor: 'middle',
        transform: `translate(-30px,${dimensions.boundedHeight /
          2}px) rotate(-90deg)`,
      }"
    >
      {{ label }}
    </text>
  </g>
</template>
<script>
export default {
  props: {
    scale: { type: Function, required: true },
    dimensions: { type: Object, required: true },
    format: {
      type: Function,
      default: (value) => value,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    ticks() {
      return this.scale.ticks().map((value) => ({
        value: this.format(value),
        offset: this.scale(value),
      }));
    },
  },
};
</script>
