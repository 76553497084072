<template>
  <rect
    :x="dimensions.margin.left"
    :y="dimensions.margin.top"
    :width="dimensions.boundedWidth"
    :height="dimensions.boundedHeight"
    fill="none"
    stroke="black"
  />
  <rect
    :x="0"
    :y="0"
    :width="dimensions.width"
    :height="dimensions.height"
    fill="none"
    stroke="black"
  />
</template>
<script>
export default {
  props: { dimensions: { type: Object, required: true } },
};
</script>
