import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import { store } from "./store";
import App from "./App.vue";
import DataViewer from "./components/DataViewer.vue";
import DragDrop from "./components/DragDrop.vue";
import CyclePoints from "./components/CyclePoints.vue";
import Wip from "@/pages/Wip.vue";

const routes = [
  { path: "/", component: DataViewer },
  { path: "/drag-drop", component: DragDrop },
  { path: "/cycle-points", component: CyclePoints },
  { path: "/wip", component: Wip },
];

const router = createRouter({ history: createWebHistory(), routes });

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
