export const mapStoryPoints = (storyPoints) => {
  if (!storyPoints) {
    return 0;
  }
  if (storyPoints <= 1.0) {
    return 1;
  }
  if (storyPoints <= 3.0) {
    return 4;
  }
  return 7;
};
