const holidays = {
  2018: [
    {
      holiday: {
        date: "2018-01-01",
        name: "Neujahr",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-05-01",
        name: "Erster Mai, Tag der Arbeit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-10-03",
        name: "Tag der Deutschen Einheit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-12-25",
        name: "1. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-12-26",
        name: "2. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-03-30",
        name: "Karfreitag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-04-01",
        name: "Ostersonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-04-02",
        name: "Ostermontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-05-10",
        name: "Himmelfahrt",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-05-20",
        name: "Pfingstsonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-05-21",
        name: "Pfingstmontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2018-01-06",
        name: "Heilige Drei K\u00f6nige",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-03-29",
        name: "Gr\u00fcndonnerstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-05-31",
        name: "Fronleichnam",
        regions: { nw: true },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-10-31",
        name: "Reformationstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-11-21",
        name: "Bu\u00df- und Bettag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-03-08",
        name: "Internationaler Frauentag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-09-20",
        name: "Weltkindertag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2018-11-01",
        name: "Allerheiligen",
        regions: { nw: true },
        all_states: false,
      },
    },
  ],
  2019: [
    {
      holiday: {
        date: "2019-01-01",
        name: "Neujahr",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-05-01",
        name: "Erster Mai, Tag der Arbeit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-10-03",
        name: "Tag der Deutschen Einheit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-12-25",
        name: "1. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-12-26",
        name: "2. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-04-19",
        name: "Karfreitag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-04-21",
        name: "Ostersonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-04-22",
        name: "Ostermontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-05-30",
        name: "Himmelfahrt",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-06-09",
        name: "Pfingstsonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-06-10",
        name: "Pfingstmontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2019-01-06",
        name: "Heilige Drei K\u00f6nige",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-04-18",
        name: "Gr\u00fcndonnerstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-06-20",
        name: "Fronleichnam",
        regions: { nw: true },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-10-31",
        name: "Reformationstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-11-20",
        name: "Bu\u00df- und Bettag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-03-08",
        name: "Internationaler Frauentag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-09-20",
        name: "Weltkindertag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2019-11-01",
        name: "Allerheiligen",
        regions: { nw: true },
        all_states: false,
      },
    },
  ],
  2020: [
    {
      holiday: {
        date: "2020-01-01",
        name: "Neujahr",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-05-01",
        name: "Erster Mai, Tag der Arbeit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-10-03",
        name: "Tag der Deutschen Einheit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-12-25",
        name: "1. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-12-26",
        name: "2. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-04-10",
        name: "Karfreitag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-04-12",
        name: "Ostersonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-04-13",
        name: "Ostermontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-05-21",
        name: "Himmelfahrt",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-05-31",
        name: "Pfingstsonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-06-01",
        name: "Pfingstmontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2020-01-06",
        name: "Heilige Drei K\u00f6nige",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-04-09",
        name: "Gr\u00fcndonnerstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-06-11",
        name: "Fronleichnam",
        regions: { nw: true },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-10-31",
        name: "Reformationstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-11-18",
        name: "Bu\u00df- und Bettag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-03-08",
        name: "Internationaler Frauentag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-05-08",
        name: "Kapitulation der Wehrmacht vor 75 Jahren",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-09-20",
        name: "Weltkindertag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2020-11-01",
        name: "Allerheiligen",
        regions: { nw: true },
        all_states: false,
      },
    },
  ],
  2021: [
    {
      holiday: {
        date: "2021-01-01",
        name: "Neujahr",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-05-01",
        name: "Erster Mai, Tag der Arbeit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-10-03",
        name: "Tag der Deutschen Einheit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-12-25",
        name: "1. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-12-26",
        name: "2. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-04-02",
        name: "Karfreitag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-04-04",
        name: "Ostersonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-04-05",
        name: "Ostermontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-05-13",
        name: "Himmelfahrt",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-05-23",
        name: "Pfingstsonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-05-24",
        name: "Pfingstmontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2021-01-06",
        name: "Heilige Drei K\u00f6nige",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-04-01",
        name: "Gr\u00fcndonnerstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-06-03",
        name: "Fronleichnam",
        regions: { nw: true },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-10-31",
        name: "Reformationstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-11-17",
        name: "Bu\u00df- und Bettag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-03-08",
        name: "Internationaler Frauentag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-09-20",
        name: "Weltkindertag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2021-11-01",
        name: "Allerheiligen",
        regions: { nw: true },
        all_states: false,
      },
    },
  ],
  2022: [
    {
      holiday: {
        date: "2022-01-01",
        name: "Neujahr",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-05-01",
        name: "Erster Mai, Tag der Arbeit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-10-03",
        name: "Tag der Deutschen Einheit",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-12-25",
        name: "1. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-12-26",
        name: "2. Weihnachtstag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-04-15",
        name: "Karfreitag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-04-17",
        name: "Ostersonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-04-18",
        name: "Ostermontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-05-26",
        name: "Himmelfahrt",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-05-05",
        name: "Pfingstsonntag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-06-06",
        name: "Pfingstmontag",
        regions: { nw: true },
        all_states: true,
      },
    },
    {
      holiday: {
        date: "2022-01-06",
        name: "Heilige Drei K\u00f6nige",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-04-14",
        name: "Gr\u00fcndonnerstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-06-16",
        name: "Fronleichnam",
        regions: { nw: true },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-10-31",
        name: "Reformationstag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-11-16",
        name: "Bu\u00df- und Bettag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-03-08",
        name: "Internationaler Frauentag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-09-20",
        name: "Weltkindertag",
        regions: { nw: false },
        all_states: false,
      },
    },
    {
      holiday: {
        date: "2022-11-01",
        name: "Allerheiligen",
        regions: { nw: true },
        all_states: false,
      },
    },
  ],
};

export const holidaysList = [
  "2018-01-01T00:00:00.000Z",
  "2018-01-02T00:00:00.000Z",
  "2018-01-06T00:00:00.000Z",
  "2018-01-07T00:00:00.000Z",
  "2018-01-13T00:00:00.000Z",
  "2018-01-14T00:00:00.000Z",
  "2018-01-20T00:00:00.000Z",
  "2018-01-21T00:00:00.000Z",
  "2018-01-27T00:00:00.000Z",
  "2018-01-28T00:00:00.000Z",
  "2018-02-03T00:00:00.000Z",
  "2018-02-04T00:00:00.000Z",
  "2018-02-10T00:00:00.000Z",
  "2018-02-11T00:00:00.000Z",
  "2018-02-17T00:00:00.000Z",
  "2018-02-18T00:00:00.000Z",
  "2018-02-24T00:00:00.000Z",
  "2018-02-25T00:00:00.000Z",
  "2018-03-03T00:00:00.000Z",
  "2018-03-04T00:00:00.000Z",
  "2018-03-10T00:00:00.000Z",
  "2018-03-11T00:00:00.000Z",
  "2018-03-17T00:00:00.000Z",
  "2018-03-18T00:00:00.000Z",
  "2018-03-24T00:00:00.000Z",
  "2018-03-25T00:00:00.000Z",
  "2018-03-29T00:00:00.000Z",
  "2018-03-30T00:00:00.000Z",
  "2018-03-31T00:00:00.000Z",
  "2018-04-01T00:00:00.000Z",
  "2018-04-02T00:00:00.000Z",
  "2018-04-03T00:00:00.000Z",
  "2018-04-07T00:00:00.000Z",
  "2018-04-08T00:00:00.000Z",
  "2018-04-14T00:00:00.000Z",
  "2018-04-15T00:00:00.000Z",
  "2018-04-21T00:00:00.000Z",
  "2018-04-22T00:00:00.000Z",
  "2018-04-28T00:00:00.000Z",
  "2018-04-29T00:00:00.000Z",
  "2018-04-30T00:00:00.000Z",
  "2018-05-01T00:00:00.000Z",
  "2018-05-05T00:00:00.000Z",
  "2018-05-06T00:00:00.000Z",
  "2018-05-10T00:00:00.000Z",
  "2018-05-11T00:00:00.000Z",
  "2018-05-12T00:00:00.000Z",
  "2018-05-13T00:00:00.000Z",
  "2018-05-19T00:00:00.000Z",
  "2018-05-20T00:00:00.000Z",
  "2018-05-21T00:00:00.000Z",
  "2018-05-22T00:00:00.000Z",
  "2018-05-26T00:00:00.000Z",
  "2018-05-27T00:00:00.000Z",
  "2018-05-31T00:00:00.000Z",
  "2018-06-01T00:00:00.000Z",
  "2018-06-02T00:00:00.000Z",
  "2018-06-03T00:00:00.000Z",
  "2018-06-09T00:00:00.000Z",
  "2018-06-10T00:00:00.000Z",
  "2018-06-16T00:00:00.000Z",
  "2018-06-17T00:00:00.000Z",
  "2018-06-23T00:00:00.000Z",
  "2018-06-24T00:00:00.000Z",
  "2018-06-30T00:00:00.000Z",
  "2018-07-01T00:00:00.000Z",
  "2018-07-07T00:00:00.000Z",
  "2018-07-08T00:00:00.000Z",
  "2018-07-14T00:00:00.000Z",
  "2018-07-15T00:00:00.000Z",
  "2018-07-21T00:00:00.000Z",
  "2018-07-22T00:00:00.000Z",
  "2018-07-28T00:00:00.000Z",
  "2018-07-29T00:00:00.000Z",
  "2018-08-04T00:00:00.000Z",
  "2018-08-05T00:00:00.000Z",
  "2018-08-11T00:00:00.000Z",
  "2018-08-12T00:00:00.000Z",
  "2018-08-18T00:00:00.000Z",
  "2018-08-19T00:00:00.000Z",
  "2018-08-25T00:00:00.000Z",
  "2018-08-26T00:00:00.000Z",
  "2018-09-01T00:00:00.000Z",
  "2018-09-02T00:00:00.000Z",
  "2018-09-08T00:00:00.000Z",
  "2018-09-09T00:00:00.000Z",
  "2018-09-15T00:00:00.000Z",
  "2018-09-16T00:00:00.000Z",
  "2018-09-22T00:00:00.000Z",
  "2018-09-23T00:00:00.000Z",
  "2018-09-29T00:00:00.000Z",
  "2018-09-30T00:00:00.000Z",
  "2018-10-03T00:00:00.000Z",
  "2018-10-06T00:00:00.000Z",
  "2018-10-07T00:00:00.000Z",
  "2018-10-13T00:00:00.000Z",
  "2018-10-14T00:00:00.000Z",
  "2018-10-20T00:00:00.000Z",
  "2018-10-21T00:00:00.000Z",
  "2018-10-27T00:00:00.000Z",
  "2018-10-28T00:00:00.000Z",
  "2018-11-01T00:00:00.000Z",
  "2018-11-02T00:00:00.000Z",
  "2018-11-03T00:00:00.000Z",
  "2018-11-04T00:00:00.000Z",
  "2018-11-10T00:00:00.000Z",
  "2018-11-11T00:00:00.000Z",
  "2018-11-17T00:00:00.000Z",
  "2018-11-18T00:00:00.000Z",
  "2018-11-24T00:00:00.000Z",
  "2018-11-25T00:00:00.000Z",
  "2018-12-01T00:00:00.000Z",
  "2018-12-02T00:00:00.000Z",
  "2018-12-08T00:00:00.000Z",
  "2018-12-09T00:00:00.000Z",
  "2018-12-15T00:00:00.000Z",
  "2018-12-16T00:00:00.000Z",
  "2018-12-22T00:00:00.000Z",
  "2018-12-23T00:00:00.000Z",
  "2018-12-24T00:00:00.000Z",
  "2018-12-25T00:00:00.000Z",
  "2018-12-26T00:00:00.000Z",
  "2018-12-27T00:00:00.000Z",
  "2018-12-28T00:00:00.000Z",
  "2018-12-29T00:00:00.000Z",
  "2018-12-30T00:00:00.000Z",
  "2018-12-31T00:00:00.000Z",

  "2019-01-01T00:00:00.000Z",
  "2019-01-05T00:00:00.000Z",
  "2019-01-06T00:00:00.000Z",
  "2019-01-12T00:00:00.000Z",
  "2019-01-13T00:00:00.000Z",
  "2019-01-19T00:00:00.000Z",
  "2019-01-20T00:00:00.000Z",
  "2019-01-26T00:00:00.000Z",
  "2019-01-27T00:00:00.000Z",
  "2019-02-02T00:00:00.000Z",
  "2019-02-03T00:00:00.000Z",
  "2019-02-09T00:00:00.000Z",
  "2019-02-10T00:00:00.000Z",
  "2019-02-16T00:00:00.000Z",
  "2019-02-17T00:00:00.000Z",
  "2019-02-23T00:00:00.000Z",
  "2019-02-24T00:00:00.000Z",
  "2019-03-02T00:00:00.000Z",
  "2019-03-03T00:00:00.000Z",
  "2019-03-09T00:00:00.000Z",
  "2019-03-10T00:00:00.000Z",
  "2019-03-16T00:00:00.000Z",
  "2019-03-17T00:00:00.000Z",
  "2019-03-23T00:00:00.000Z",
  "2019-03-24T00:00:00.000Z",
  "2019-03-30T00:00:00.000Z",
  "2019-03-31T00:00:00.000Z",
  "2019-04-06T00:00:00.000Z",
  "2019-04-07T00:00:00.000Z",
  "2019-04-13T00:00:00.000Z",
  "2019-04-14T00:00:00.000Z",
  "2019-04-18T00:00:00.000Z",
  "2019-04-19T00:00:00.000Z",
  "2019-04-20T00:00:00.000Z",
  "2019-04-21T00:00:00.000Z",
  "2019-04-22T00:00:00.000Z",
  "2019-04-23T00:00:00.000Z",
  "2019-04-27T00:00:00.000Z",
  "2019-04-28T00:00:00.000Z",
  "2019-05-01T00:00:00.000Z",
  "2019-05-04T00:00:00.000Z",
  "2019-05-05T00:00:00.000Z",
  "2019-05-11T00:00:00.000Z",
  "2019-05-12T00:00:00.000Z",
  "2019-05-18T00:00:00.000Z",
  "2019-05-19T00:00:00.000Z",
  "2019-05-25T00:00:00.000Z",
  "2019-05-26T00:00:00.000Z",
  "2019-05-30T00:00:00.000Z",
  "2019-05-31T00:00:00.000Z",
  "2019-06-01T00:00:00.000Z",
  "2019-06-02T00:00:00.000Z",
  "2019-06-08T00:00:00.000Z",
  "2019-06-09T00:00:00.000Z",
  "2019-06-10T00:00:00.000Z",
  "2019-06-11T00:00:00.000Z",
  "2019-06-15T00:00:00.000Z",
  "2019-06-16T00:00:00.000Z",
  "2019-06-20T00:00:00.000Z",
  "2019-06-21T00:00:00.000Z",
  "2019-06-22T00:00:00.000Z",
  "2019-06-23T00:00:00.000Z",
  "2019-06-29T00:00:00.000Z",
  "2019-06-30T00:00:00.000Z",
  "2019-07-06T00:00:00.000Z",
  "2019-07-07T00:00:00.000Z",
  "2019-07-13T00:00:00.000Z",
  "2019-07-14T00:00:00.000Z",
  "2019-07-20T00:00:00.000Z",
  "2019-07-21T00:00:00.000Z",
  "2019-07-27T00:00:00.000Z",
  "2019-07-28T00:00:00.000Z",
  "2019-08-03T00:00:00.000Z",
  "2019-08-04T00:00:00.000Z",
  "2019-08-10T00:00:00.000Z",
  "2019-08-11T00:00:00.000Z",
  "2019-08-17T00:00:00.000Z",
  "2019-08-18T00:00:00.000Z",
  "2019-08-24T00:00:00.000Z",
  "2019-08-25T00:00:00.000Z",
  "2019-08-31T00:00:00.000Z",
  "2019-09-01T00:00:00.000Z",
  "2019-09-07T00:00:00.000Z",
  "2019-09-08T00:00:00.000Z",
  "2019-09-14T00:00:00.000Z",
  "2019-09-15T00:00:00.000Z",
  "2019-09-21T00:00:00.000Z",
  "2019-09-22T00:00:00.000Z",
  "2019-09-28T00:00:00.000Z",
  "2019-09-29T00:00:00.000Z",
  "2019-10-03T00:00:00.000Z",
  "2019-10-04T00:00:00.000Z",
  "2019-10-05T00:00:00.000Z",
  "2019-10-06T00:00:00.000Z",
  "2019-10-12T00:00:00.000Z",
  "2019-10-13T00:00:00.000Z",
  "2019-10-19T00:00:00.000Z",
  "2019-10-20T00:00:00.000Z",
  "2019-10-26T00:00:00.000Z",
  "2019-10-27T00:00:00.000Z",
  "2019-10-31T00:00:00.000Z",
  "2019-11-01T00:00:00.000Z",
  "2019-11-02T00:00:00.000Z",
  "2019-11-03T00:00:00.000Z",
  "2019-11-09T00:00:00.000Z",
  "2019-11-10T00:00:00.000Z",
  "2019-11-16T00:00:00.000Z",
  "2019-11-17T00:00:00.000Z",
  "2019-11-23T00:00:00.000Z",
  "2019-11-24T00:00:00.000Z",
  "2019-11-30T00:00:00.000Z",
  "2019-12-01T00:00:00.000Z",
  "2019-12-07T00:00:00.000Z",
  "2019-12-08T00:00:00.000Z",
  "2019-12-14T00:00:00.000Z",
  "2019-12-15T00:00:00.000Z",
  "2019-12-21T00:00:00.000Z",
  "2019-12-22T00:00:00.000Z",
  "2019-12-24T00:00:00.000Z",
  "2019-12-25T00:00:00.000Z",
  "2019-12-26T00:00:00.000Z",
  "2019-12-27T00:00:00.000Z",
  "2019-12-28T00:00:00.000Z",
  "2019-12-29T00:00:00.000Z",
  "2019-12-30T00:00:00.000Z",
  "2019-12-31T00:00:00.000Z",

  "2020-01-01T00:00:00.000Z",
  "2020-01-04T00:00:00.000Z",
  "2020-01-05T00:00:00.000Z",
  "2020-01-11T00:00:00.000Z",
  "2020-01-12T00:00:00.000Z",
  "2020-01-18T00:00:00.000Z",
  "2020-01-19T00:00:00.000Z",
  "2020-01-25T00:00:00.000Z",
  "2020-01-26T00:00:00.000Z",
  "2020-02-01T00:00:00.000Z",
  "2020-02-02T00:00:00.000Z",
  "2020-02-08T00:00:00.000Z",
  "2020-02-09T00:00:00.000Z",
  "2020-02-15T00:00:00.000Z",
  "2020-02-16T00:00:00.000Z",
  "2020-02-22T00:00:00.000Z",
  "2020-02-23T00:00:00.000Z",
  "2020-02-29T00:00:00.000Z",
  "2020-03-01T00:00:00.000Z",
  "2020-03-07T00:00:00.000Z",
  "2020-03-08T00:00:00.000Z",
  "2020-03-14T00:00:00.000Z",
  "2020-03-15T00:00:00.000Z",
  "2020-03-21T00:00:00.000Z",
  "2020-03-22T00:00:00.000Z",
  "2020-03-28T00:00:00.000Z",
  "2020-03-29T00:00:00.000Z",
  "2020-04-04T00:00:00.000Z",
  "2020-04-05T00:00:00.000Z",
  "2020-04-09T00:00:00.000Z",
  "2020-04-10T00:00:00.000Z",
  "2020-04-11T00:00:00.000Z",
  "2020-04-12T00:00:00.000Z",
  "2020-04-13T00:00:00.000Z",
  "2020-04-14T00:00:00.000Z",
  "2020-04-18T00:00:00.000Z",
  "2020-04-19T00:00:00.000Z",
  "2020-04-25T00:00:00.000Z",
  "2020-04-26T00:00:00.000Z",
  "2020-04-30T00:00:00.000Z",
  "2020-05-01T00:00:00.000Z",
  "2020-05-02T00:00:00.000Z",
  "2020-05-03T00:00:00.000Z",
  "2020-05-09T00:00:00.000Z",
  "2020-05-10T00:00:00.000Z",
  "2020-05-16T00:00:00.000Z",
  "2020-05-17T00:00:00.000Z",
  "2020-05-21T00:00:00.000Z",
  "2020-05-22T00:00:00.000Z",
  "2020-05-23T00:00:00.000Z",
  "2020-05-24T00:00:00.000Z",
  "2020-05-30T00:00:00.000Z",
  "2020-05-31T00:00:00.000Z",
  "2020-06-01T00:00:00.000Z",
  "2020-06-02T00:00:00.000Z",
  "2020-06-06T00:00:00.000Z",
  "2020-06-07T00:00:00.000Z",
  "2020-06-11T00:00:00.000Z",
  "2020-06-12T00:00:00.000Z",
  "2020-06-13T00:00:00.000Z",
  "2020-06-14T00:00:00.000Z",
  "2020-06-20T00:00:00.000Z",
  "2020-06-21T00:00:00.000Z",
  "2020-06-27T00:00:00.000Z",
  "2020-06-28T00:00:00.000Z",
  "2020-07-04T00:00:00.000Z",
  "2020-07-05T00:00:00.000Z",
  "2020-07-11T00:00:00.000Z",
  "2020-07-12T00:00:00.000Z",
  "2020-07-18T00:00:00.000Z",
  "2020-07-19T00:00:00.000Z",
  "2020-07-25T00:00:00.000Z",
  "2020-07-26T00:00:00.000Z",
  "2020-08-01T00:00:00.000Z",
  "2020-08-02T00:00:00.000Z",
  "2020-08-08T00:00:00.000Z",
  "2020-08-09T00:00:00.000Z",
  "2020-08-15T00:00:00.000Z",
  "2020-08-16T00:00:00.000Z",
  "2020-08-22T00:00:00.000Z",
  "2020-08-23T00:00:00.000Z",
  "2020-08-29T00:00:00.000Z",
  "2020-08-30T00:00:00.000Z",
  "2020-09-05T00:00:00.000Z",
  "2020-09-06T00:00:00.000Z",
  "2020-09-12T00:00:00.000Z",
  "2020-09-13T00:00:00.000Z",
  "2020-09-19T00:00:00.000Z",
  "2020-09-20T00:00:00.000Z",
  "2020-09-26T00:00:00.000Z",
  "2020-09-27T00:00:00.000Z",
  "2020-10-03T00:00:00.000Z",
  "2020-10-04T00:00:00.000Z",
  "2020-10-10T00:00:00.000Z",
  "2020-10-11T00:00:00.000Z",
  "2020-10-17T00:00:00.000Z",
  "2020-10-18T00:00:00.000Z",
  "2020-10-24T00:00:00.000Z",
  "2020-10-25T00:00:00.000Z",
  "2020-10-31T00:00:00.000Z",
  "2020-11-01T00:00:00.000Z",
  "2020-11-07T00:00:00.000Z",
  "2020-11-08T00:00:00.000Z",
  "2020-11-14T00:00:00.000Z",
  "2020-11-15T00:00:00.000Z",
  "2020-11-21T00:00:00.000Z",
  "2020-11-22T00:00:00.000Z",
  "2020-11-28T00:00:00.000Z",
  "2020-11-29T00:00:00.000Z",
  "2020-12-05T00:00:00.000Z",
  "2020-12-06T00:00:00.000Z",
  "2020-12-12T00:00:00.000Z",
  "2020-12-13T00:00:00.000Z",
  "2020-12-19T00:00:00.000Z",
  "2020-12-20T00:00:00.000Z",
  "2020-12-24T00:00:00.000Z",
  "2020-12-25T00:00:00.000Z",
  "2020-12-26T00:00:00.000Z",
  "2020-12-27T00:00:00.000Z",
  "2020-12-28T00:00:00.000Z",
  "2020-12-29T00:00:00.000Z",
  "2020-12-30T00:00:00.000Z",
  "2020-12-31T00:00:00.000Z",

  "2021-01-01T00:00:00.000Z",
  "2021-01-02T00:00:00.000Z",
  "2021-01-03T00:00:00.000Z",
  "2021-01-09T00:00:00.000Z",
  "2021-01-10T00:00:00.000Z",
  "2021-01-16T00:00:00.000Z",
  "2021-01-17T00:00:00.000Z",
  "2021-01-23T00:00:00.000Z",
  "2021-01-24T00:00:00.000Z",
  "2021-01-30T00:00:00.000Z",
  "2021-01-31T00:00:00.000Z",
  "2021-02-06T00:00:00.000Z",
  "2021-02-07T00:00:00.000Z",
  "2021-02-13T00:00:00.000Z",
  "2021-02-14T00:00:00.000Z",
  "2021-02-20T00:00:00.000Z",
  "2021-02-21T00:00:00.000Z",
  "2021-02-27T00:00:00.000Z",
  "2021-02-28T00:00:00.000Z",
  "2021-03-06T00:00:00.000Z",
  "2021-03-07T00:00:00.000Z",
  "2021-03-13T00:00:00.000Z",
  "2021-03-14T00:00:00.000Z",
  "2021-03-20T00:00:00.000Z",
  "2021-03-21T00:00:00.000Z",
  "2021-03-27T00:00:00.000Z",
  "2021-03-28T00:00:00.000Z",
  "2021-04-01T00:00:00.000Z",
  "2021-04-02T00:00:00.000Z",
  "2021-04-03T00:00:00.000Z",
  "2021-04-04T00:00:00.000Z",
  "2021-04-05T00:00:00.000Z",
  "2021-04-06T00:00:00.000Z",
  "2021-04-10T00:00:00.000Z",
  "2021-04-11T00:00:00.000Z",
  "2021-04-17T00:00:00.000Z",
  "2021-04-18T00:00:00.000Z",
  "2021-04-24T00:00:00.000Z",
  "2021-04-25T00:00:00.000Z",
  "2021-05-01T00:00:00.000Z",
  "2021-05-02T00:00:00.000Z",
  "2021-05-08T00:00:00.000Z",
  "2021-05-09T00:00:00.000Z",
  "2021-05-13T00:00:00.000Z",
  "2021-05-14T00:00:00.000Z",
  "2021-05-15T00:00:00.000Z",
  "2021-05-16T00:00:00.000Z",
  "2021-05-22T00:00:00.000Z",
  "2021-05-23T00:00:00.000Z",
  "2021-05-24T00:00:00.000Z",
  "2021-05-25T00:00:00.000Z",
  "2021-05-29T00:00:00.000Z",
  "2021-05-30T00:00:00.000Z",
  "2021-06-03T00:00:00.000Z",
  "2021-06-04T00:00:00.000Z",
  "2021-06-05T00:00:00.000Z",
  "2021-06-06T00:00:00.000Z",
  "2021-06-12T00:00:00.000Z",
  "2021-06-13T00:00:00.000Z",
  "2021-06-19T00:00:00.000Z",
  "2021-06-20T00:00:00.000Z",
  "2021-06-26T00:00:00.000Z",
  "2021-06-27T00:00:00.000Z",
  "2021-07-03T00:00:00.000Z",
  "2021-07-04T00:00:00.000Z",
  "2021-07-10T00:00:00.000Z",
  "2021-07-11T00:00:00.000Z",
  "2021-07-17T00:00:00.000Z",
  "2021-07-18T00:00:00.000Z",
  "2021-07-24T00:00:00.000Z",
  "2021-07-25T00:00:00.000Z",
  "2021-07-31T00:00:00.000Z",
  "2021-08-01T00:00:00.000Z",
  "2021-08-07T00:00:00.000Z",
  "2021-08-08T00:00:00.000Z",
  "2021-08-14T00:00:00.000Z",
  "2021-08-15T00:00:00.000Z",
  "2021-08-21T00:00:00.000Z",
  "2021-08-22T00:00:00.000Z",
  "2021-08-28T00:00:00.000Z",
  "2021-08-29T00:00:00.000Z",
  "2021-09-04T00:00:00.000Z",
  "2021-09-05T00:00:00.000Z",
  "2021-09-11T00:00:00.000Z",
  "2021-09-12T00:00:00.000Z",
  "2021-09-18T00:00:00.000Z",
  "2021-09-19T00:00:00.000Z",
  "2021-09-25T00:00:00.000Z",
  "2021-09-26T00:00:00.000Z",
  "2021-10-02T00:00:00.000Z",
  "2021-10-03T00:00:00.000Z",
  "2021-10-09T00:00:00.000Z",
  "2021-10-10T00:00:00.000Z",
  "2021-10-16T00:00:00.000Z",
  "2021-10-17T00:00:00.000Z",
  "2021-10-23T00:00:00.000Z",
  "2021-10-24T00:00:00.000Z",
  "2021-10-30T00:00:00.000Z",
  "2021-10-31T00:00:00.000Z",
  "2021-11-01T00:00:00.000Z",
  "2021-11-02T00:00:00.000Z",
  "2021-11-06T00:00:00.000Z",
  "2021-11-07T00:00:00.000Z",
  "2021-11-13T00:00:00.000Z",
  "2021-11-14T00:00:00.000Z",
  "2021-11-20T00:00:00.000Z",
  "2021-11-21T00:00:00.000Z",
  "2021-11-27T00:00:00.000Z",
  "2021-11-28T00:00:00.000Z",
  "2021-12-04T00:00:00.000Z",
  "2021-12-05T00:00:00.000Z",
  "2021-12-11T00:00:00.000Z",
  "2021-12-12T00:00:00.000Z",
  "2021-12-18T00:00:00.000Z",
  "2021-12-19T00:00:00.000Z",
  "2021-12-24T00:00:00.000Z",
  "2021-12-25T00:00:00.000Z",
  "2021-12-26T00:00:00.000Z",
  "2021-12-27T00:00:00.000Z",
  "2021-12-28T00:00:00.000Z",
  "2021-12-29T00:00:00.000Z",
  "2021-12-30T00:00:00.000Z",
  "2021-12-31T00:00:00.000Z",
];
export const holidaysSet = new Set(holidaysList);

export const utcDateFromString = (s) => {
  const [year, month, day] = s.split("-").map(Number);
  return new Date(Date.UTC(year, month - 1, day));
};

export const createAdd = (daysToAdd) => (date) => {
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate() + daysToAdd
    )
  );
};

export const copyDate = createAdd(0);
export const trimDate = createAdd(0);
export const addOneDay = createAdd(1);
export const subtractOneDay = createAdd(-1);

export const createInterval = (date) => {
  return {
    start: copyDate(date),
    end: new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23)
    ),
  };
};

export const calcHolidays = (year) => {
  if (!holidays[year]) {
    throw new Error(`No holiday data for year '${year}'!`);
  }
  const dates = holidays[year]
    .filter(({ holiday }) => holiday.regions.nw)
    .map(({ holiday }) => utcDateFromString(holiday.date));
  const holidaySet = new Set();
  for (const date of dates) {
    holidaySet.add(date.toISOString());
    if (date.getDay() === 1 || date.getDay() === 4) {
      holidaySet.add(addOneDay(date).toISOString());
    }
    if (date.getDay() === 2 || date.getDay() === 5) {
      holidaySet.add(subtractOneDay(date).toISOString());
    }
  }
  let christmasHoliday = new Date(Date.UTC(year, 11, 24));
  const end = new Date(Date.UTC(year + 1, 0, 1));
  while (christmasHoliday < end) {
    holidaySet.add(christmasHoliday.toISOString());
    christmasHoliday = addOneDay(christmasHoliday);
  }
  return [...holidaySet]
    .sort((a, b) => a.localeCompare(b))
    .map((s) => new Date(s))
    .filter((date) => date.getFullYear() === year);
};

export const calcWeekends = (year) => {
  let date = new Date(Date.UTC(year, 0, 1));
  const end = new Date(Date.UTC(year + 1, 0, 1));
  const freeDays = new Set();
  while (date < end) {
    if ([0, 6].includes(date.getDay())) {
      freeDays.add(date);
    }
    date = addOneDay(date);
  }
  return [...freeDays];
};

export const calcFreeDays = (year) => {
  const weekends = calcWeekends(year);
  let holidayDays;
  try {
    holidayDays = calcHolidays(year);
  } catch (err) {
    holidayDays = [];
  }

  return [...new Set([...weekends, ...holidayDays].map((d) => d.toISOString()))]
    .map((s) => new Date(s))
    .sort((a, b) => a - b);
};

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const calcDuration = ({ start, end, result = 0 }) => {
  if (start >= end) {
    return result;
  }
  const trimmedStart = trimDate(start);
  if (holidaysSet.has(trimmedStart.toISOString())) {
    return calcDuration({ start: addOneDay(trimmedStart), end, result });
  }
  if (end - start < ONE_DAY_IN_MS) {
    return result + (end - start);
  }
  const tomorrow = addOneDay(trimmedStart);
  return calcDuration({
    start: tomorrow,
    end,
    result: result + (tomorrow - start),
  });
};

export const formatDate = (value) => {
  return new Date(value).toLocaleDateString("de-DE", {
    month: "2-digit",
    year: "2-digit",
    day: "2-digit",
  });
};
