<template>
  <svg :width="dimensions.width" :height="dimensions.height">
    <ChartBoundaries :dimensions="dimensions" />
    <g
      :style="
        `transform: translate(${dimensions.margin.left}px, ${dimensions.margin.top}px)`
      "
    >
      <slot name="content"></slot>
    </g>
    <slot name="axis"></slot>
  </svg>
</template>
<script>
import ChartBoundaries from "./ChartBoundaries";

export default {
  props: { dimensions: { type: Object, required: true } },
  components: {
    ChartBoundaries,
  },
};
</script>
