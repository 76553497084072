const getRanges = (issue, inProcessStates) => {
  if (issue.changelog.length === 0) {
    return new Set();
  }
  const changelog = [...issue.changelog].sort((a, b) =>
    a.date.localeCompare(b.date)
  );

  const result = [];

  let start = null;
  for (const log of changelog) {
    if (!inProcessStates.has(log.from) && inProcessStates.has(log.to)) {
      start = new Date(log.date);
      result.push({ type: "start", issue, date: start });
    }
    if (start && !inProcessStates.has(log.to)) {
      result.push({ type: "stop", issue, date: new Date(log.date) });
      start = null;
    }
  }
  return result;
};

export const calculateWip = (issues, inProcessStates) => {
  const events = [];
  for (const issue of issues) {
    const ranges = getRanges(issue, inProcessStates);
    events.push(...ranges);
  }
  events.sort((a, b) => a.date - b.date);

  const current = new Set();
  const result = [];
  for (const event of events) {
    if (event.type === "start") {
      current.add(event.issue);
    }
    if (event.type === "stop") {
      current.delete(event.issue);
    }
    result.push({ date: event.date, issues: [...current] });
  }
  return result;
};
