<template>
  <h2>WIP over Time</h2>
  <Wip :data="data" :settings="settings" />
  <h2>Throughput over Time</h2>
  <Throughput :data="data" :settings="settings" :dimensions="dimensions" />
</template>
<script>
import Wip from "@/components/Wip";
import Throughput from "@/components/Throughput";

import { calcDimensions } from "@/math/dimensions";

const dimensions = calcDimensions({
  width: 1300,
  height: 400,
  margin: { top: 15, right: 40, bottom: 50, left: 50 },
});

export default {
  props: ["data", "settings"],
  components: {
    Wip,
    Throughput,
  },
  setup() {
    return { dimensions };
  },
};
</script>
