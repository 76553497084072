<template>
  <h2>Current Data</h2>
  <pre>{{ data }}</pre>
</template>

<script>
export default {
  props: ["data", "settings"],
};
</script>
