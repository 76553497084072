<template>
  <h1>Jira Data Visualizer</h1>
  <FileReader @loaded-data="persistData" v-slot="{ chooseFile }">
    <button @click.prevent="chooseFile">Choose new Jira File</button>
  </FileReader>
  <p class="navigation">
    <router-link to="/">View Raw Data</router-link>
    <router-link to="/drag-drop">Global Settings</router-link>
    <router-link to="/cycle-points">Cycle Time-Story Points Chart</router-link>
    <router-link to="/wip">WIP Chart</router-link>
  </p>
  <router-view :data="data" :settings="settings"></router-view>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import FileReader from "./components/FileReader";

export default {
  components: {
    FileReader,
  },
  setup() {
    const store = useStore();
    const persistData = (event) => {
      store.commit("setData", Object.freeze(JSON.parse(event)));
    };
    return {
      data: computed(() => store.getters.data),
      settings: computed(() => store.state.settings),
      persistData,
    };
  },
};
</script>
<style scoped>
.navigation a {
  margin: 5px;
}
</style>
