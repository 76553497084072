<template>
  <BaseChart :dimensions="dim">
    <template #content>
      <circle
        v-for="{ cx, cy, idx } in points"
        :key="idx"
        :cx="cx"
        :cy="cy"
        r="3"
        fill="cornflowerblue"
      />
      <g v-for="{ y, value, key } in quantiles" :key="key">
        <line :y1="y" :y2="y" :x1="0" :x2="dim.boundedWidth" stroke="black" />
        <text :y="y" :x="dim.boundedWidth + 15" text-anchor="middle">
          {{ value }}
        </text>
      </g>
    </template>
    <template #axis>
      <AxisBottom
        :scale="xScale"
        :dimensions="dim"
        label="Date"
        :format="formatDate"
      />
      <AxisLeft :scale="yScale" :dimensions="dim" label="WIP" />
    </template>
  </BaseChart>
</template>
<script>
import { scaleLinear, scaleTime, extent, quantile } from "d3";

import AxisBottom from "./AxisBottom";
import AxisLeft from "./AxisLeft";
import BaseChart from "./BaseChart";

import { calculateWip } from "@/math/wip";

const xAccessor = (d) => new Date(d.date);
const yAccessor = (d) =>
  d.issues
    .map((issue) => {
      const storyPoints = issue.fields["Story Points"];
      if (storyPoints <= 1.0) {
        return 1;
      }
      if (storyPoints <= 3.0) {
        return 4;
      }
      return 7;
    })
    .reduce((acc, cur) => acc + cur, 0);

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    settings: {
      required: true,
    },
  },
  components: {
    AxisBottom,
    AxisLeft,
    BaseChart,
  },
  data() {
    return {
      dimensions: {
        width: 1300,
        height: 400,
        margin: { top: 15, right: 40, bottom: 50, left: 50 },
      },
    };
  },
  computed: {
    quantiles() {
      const wipValues = this.wip.map(yAccessor);
      return [0.5, 0.7, 0.85, 0.95]
        .map((q) => Math.round(quantile(wipValues, q)))
        .map((value, idx) => ({ y: this.yScale(value), value, key: idx }));
    },
    wip() {
      const result = calculateWip(
        this.data.filter((issue) => issue.fields["Story Points"]),
        new Set(this.settings.workflow.inProcess.map(({ id }) => id))
      );
      window.wip = result;
      window.data = this.data;
      return result;
    },
    xData() {
      return this.wip.map(xAccessor);
    },
    yData() {
      return this.wip.map(yAccessor);
    },
    xScale() {
      return scaleTime()
        .domain(extent(this.xData))
        .range([0, this.dim.boundedWidth])
        .nice();
    },
    yScale() {
      return scaleLinear()
        .domain(extent(this.yData))
        .range([this.dim.boundedHeight, 0])
        .nice();
    },
    points() {
      return this.wip.map((d, idx) => ({
        cx: this.xScale(xAccessor(d)),
        cy: this.yScale(yAccessor(d)),
        idx,
      }));
    },
    dim() {
      return {
        ...this.dimensions,
        boundedWidth:
          this.dimensions.width -
          this.dimensions.margin.left -
          this.dimensions.margin.right,
        boundedHeight:
          this.dimensions.height -
          this.dimensions.margin.top -
          this.dimensions.margin.bottom,
      };
    },
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("de-DE", {
        month: "2-digit",
        year: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>
