import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const getStateAndFreezeProperties = (key, storage) => {
  const value = storage.getItem(key);
  if (typeof value === "undefined") {
    return undefined;
  }
  try {
    const parsedValue = JSON.parse(value);
    for (const key of Object.keys(parsedValue)) {
      parsedValue[key] = Object.freeze(parsedValue[key]);
    }
    return parsedValue;
  } catch (err) {
    return undefined;
  }
};
export const store = createStore({
  state() {
    return {
      data: [],
      settings: {},
    };
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
  },
  getters: {
    data(state) {
      const issuesTypesToAnalyze = new Set(
        state.settings.issues.analyze.map(({ id }) => id)
      );
      return state.data.filter((issue) =>
        issuesTypesToAnalyze.has(issue.type.id)
      );
    },
  },
  strict: true,
  plugins: [
    createPersistedState({
      overwrite: true,
      getState: getStateAndFreezeProperties,
    }),
  ],
});
